<template>

  <!-- Administration Button -->
  <button
    class="adm-button"
    :disabled="disabled || loading"
    :autofocus="autofocus"
    :type="nativeType"
    :class="[
      'adm-button__' + type + '__' + color,
      'adm-button__size__' + size,
      {
        'is-disabled': disabled,
        'is-loading': loading,
        'is-icon-start': iconStart && $slots.default && !iconOnly,
        'is-icon-end': iconEnd && $slots.default && !iconOnly,
        'is-icon-only': ((iconStart || iconEnd) && !$slots.default) || iconOnly
      }
    ]"
    :style="{ width: fullWidth ? '100%' : null}"
    @click="handleClick"
    @mousedown="handleMouseDown"
  >
    <span v-if="loading" class="adm-button-spinner-container">
      <svg viewBox="0 0 50 50" width="50px" height="50px" class="adm-button-spinner">
        <circle class="adm-button-spinner__ring" cx="25" cy="25" r="22.5" />
        <circle class="adm-button-spinner__line" cx="25" cy="25" r="22.5" />
      </svg>
    </span>

    <span v-if="iconStart && !loading" class="adm-button__icon-wrapper">
      <slot name="icon-start">
        <i :class="'tz-' + iconStart" @click="$emit('icon-start-click')" />
      </slot>
    </span>
    <span v-if="!iconOnly">
      <slot />
    </span>
    <i v-if="iconEnd" :class="'tz-' + iconEnd" @click="(e) => $emit('icon-end-click', e)" />
  </button>
  <!-- /Administration Button -->

</template>

<script>
export default {
  name: 'AdmButton',

  props: {
    type: {
      type: String,
      default: 'filled',
      validator (value) {
        return ['filled', 'ghost', 'borderless'].indexOf(value) !== -1
      }
    },
    size: {
      type: String,
      default: 'default',
      validator (value) {
        return ['default', 'medium', 'small', 'mini', 'micro'].indexOf(value) !== -1
      }
    },
    color: {
      type: String,
      default: 'blue',
      validator (value) {
        return ['blue', 'grey', 'red', 'green', 'dark', 'light'].indexOf(value) !== -1
      }
    },
    iconStart: {
      type: String,
      default: ''
    },
    iconEnd: {
      type: String,
      default: ''
    },
    nativeType: {
      type: String,
      default: 'button'
    },
    loading: Boolean,
    disabled: Boolean,
    autofocus: Boolean,
    fullWidth: Boolean,
    iconOnly: Boolean
  },

  methods: {
    handleClick (event) {
      document?.activeElement?.blur()
      this.$emit('click', event)
    },

    handleMouseDown (event) {
      event.preventDefault()
    }
  }
}
</script>

<style lang="scss">
// Administration Button
button.adm-button {
  --spinner-line-color: #FFFFFF;
  --spinner-container-width: 24px;
  --spinner-container-height: 24px;
  font-weight: 500;
  border-radius: 7px;
  outline: none;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  -webkit-transition: background 0.15s;
  transition: background 0.15s;

  $adm-button-icon-wrapper: '.adm-button__icon-wrapper';

  #{$adm-button-icon-wrapper} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &.is-icon-only {
    flex-shrink: 0;
  }

  // Size
  &__size {

    // Default
    &__default {
      height: 40px;
      font-size: 15px;
      line-height: 24px;
      padding: 0 24px;

      // Icon
      i {
        font-size: 24px;
      }

      // Focused Filled
      &:focus:not(:active):not(.adm-button__filled__grey):not([class*='adm-button__ghost__']):not([class*='adm-button__borderless__']):not(.is-icon-only) {
        padding: 0 22px;
      }

      // Focused Ghost & Borderless
      &[class*='adm-button__ghost__'], &[class*='adm-button__borderless__'] {
        padding: 0 23px;
      }

      // Icon Start
      &.is-icon-start {
        #{$adm-button-icon-wrapper} {
          margin-right: 8px;
          margin-left: -4px;
        }
      }

      // Icon End
      &.is-icon-end {
        i {
          margin-left: 8px;
          margin-right: -4px;
        }
      }

      // Icon Only
      &.is-icon-only {
        width: 40px;
        height: 40px;
        padding: 0;
      }

      // Loading
      &.is-loading {
        .adm-button-spinner-container {
          margin-right: 8px;
          margin-left: -4px;
        }
      }

      &.is-icon-only {
        .adm-button-spinner-container {
          margin: 0;
        }
      }
    }

    // Medium
    &__medium {
      height: 36px;
      font-size: 14px;
      line-height: 20px;
      padding: 0 20px;

      // Icon
      i {
        font-size: 24px;
      }

      // Focused Filled
      &:focus:not(:active):not(.adm-button__filled__grey):not([class*='adm-button__ghost__']):not([class*='adm-button__borderless__']):not(.is-icon-only) {
        padding: 0 18px;
      }

      // Focused Ghost & Borderless
      &[class*='adm-button__ghost__'], &[class*='adm-button__borderless__'] {
        padding: 0 19px;
      }

      // Icon Start
      &.is-icon-start {
        #{$adm-button-icon-wrapper} {
          margin-right: 4px;
          margin-left: -4px;
        }
      }

      // Icon End
      &.is-icon-end {
        i {
          margin-left: 4px;
          margin-right: -4px;
        }
      }

      // Icon Only
      &.is-icon-only {
        width: 36px;
        height: 36px;
        padding: 0;
      }

      // Loading
      &.is-loading {
        .adm-button-spinner-container {
          margin-right: 4px;
          margin-left: -4px;
        }
      }
    }

    // Small
    &__small {
      --spinner-container-width: 20px;
      --spinner-container-height: 20px;
      height: 32px;
      font-size: 14px;
      line-height: 20px;
      padding: 0 16px;

      // Icon
      i {
        font-size: 20px;
      }

      // Focused Filled
      &:focus:not(:active):not(.adm-button__filled__grey):not([class*='adm-button__ghost__']):not([class*='adm-button__borderless__']):not(.is-icon-only) {
        padding: 0 14px;
      }

      // Focused Ghost & Borderless
      &[class*='adm-button__ghost__'], &[class*='adm-button__borderless__'] {
        padding: 0 15px;
      }

      // Icon Start
      &.is-icon-start {
        #{$adm-button-icon-wrapper} {
          margin-right: 4px;
          margin-left: -4px;
        }
      }

      // Icon End
      &.is-icon-end {
        i {
          margin-left: 4px;
          margin-right: -4px;
        }
      }

      // Icon Only
      &.is-icon-only {
        width: 32px;
        height: 32px;
        padding: 0;
      }

      // Loading
      &.is-loading {
        .adm-button-spinner-container {
          margin-right: 4px;
          margin-left: -4px;
        }
      }
    }

    // Mini
    &__mini {
      --spinner-container-width: 20px;
      --spinner-container-height: 20px;
      height: 28px;
      font-size: 13px;
      line-height: 20px;
      padding: 0 8px;

      // Icon
      i {
        font-size: 20px;
      }

      // Focused Filled
      &:focus:not(:active):not(.adm-button__filled__grey):not([class*='adm-button__ghost__']):not([class*='adm-button__borderless__']):not(.is-icon-only) {
        padding: 0 6px;
      }

      // Focused Ghost & Borderless
      &[class*='adm-button__ghost__'], &[class*='adm-button__borderless__'] {
        padding: 0 7px;
      }

      // Icon Start
      &.is-icon-start {
        #{$adm-button-icon-wrapper} {
          margin-right: 4px;
          margin-left: -2px;
          height: 20px;
          width: 20px;
        }
      }

      // Icon End
      &.is-icon-end {
        i {
          margin-left: 4px;
          margin-right: -2px;
        }
      }

      // Icon Only
      &.is-icon-only {
        width: 28px;
        height: 28px;
        padding: 0;
      }

      // Loading
      &.is-loading {
        .adm-button-spinner-container {
          margin-right: 4px;
          margin-left: -2px;
        }
      }
    }

    // Micro
    &__micro {
      --spinner-container-width: 20px;
      --spinner-container-height: 20px;
      height: 24px;
      font-size: 13px;
      line-height: 20px;
      padding: 0 6px;

      // Icon
      i {
        font-size: 20px;
      }

      // Focused Filled
      &:focus:not(:active):not(.adm-button__filled__grey):not([class*='adm-button__ghost__']):not([class*='adm-button__borderless__']):not(.is-icon-only) {
        padding: 0 4px;
      }

      // Focused Ghost & Borderless
      &[class*='adm-button__ghost__'], &[class*='adm-button__borderless__'] {
        padding: 0 5px;
      }

      // Icon Start
      &.is-icon-start {
        #{$adm-button-icon-wrapper} {
          margin-right: 4px;
          margin-left: -2px;
          height: 20px;
          width: 20px;
        }
      }

      // Icon End
      &.is-icon-end {
        i {
          margin-left: 4px;
          margin-right: -2px;
        }
      }

      // Icon Only
      &.is-icon-only {
        width: 24px;
        height: 24px;
        padding: 0;
      }

      // Loading
      &.is-loading {
        .adm-button-spinner-container {
          margin-right: 4px;
          margin-left: -2px;
        }
      }
    }
  }

  // Filled
  &__filled {

    // Blue
    &__blue {
      background-color: var(--primary-900);
      color: $adm-white;

      // Hover
      &:hover {
        background-color: var(--primary-800);
      }

      // Focus
      &:focus:not(:active), &.is-focused {
        background-color: var(--primary-900);
        border: 2px solid $adm-white;
        box-shadow: 0 0 0 2px var(--primary-900);
      }

      // Active
      &:active, &.is-active {
        background: var(--primary-1000);
      }

      // Disabled & Loading
      &.is-disabled, &.is-loading {
        background: var(--primary-600);
      }
    }

    // Grey
    &__grey {
      background-color: $shade-250;
      color: $shade-900;

      // Hover
      &:hover {
        background-color: $shade-200;
      }

      // Focus
      &:focus:not(:active), &.is-focused {
        background: $shade-250;
        box-shadow: 0 0 0 1px var(--primary-900);
      }

      // Active
      &:active, &.is-active {
        background: $shade-300;
      }

      // Disabled & Loading
      &.is-disabled, &.is-loading {
        background-color: $shade-200;
        --spinner-line-color: #99a1b1;

        span, i {
          color: $shade-700;
        }
      }
    }

    // Red
    &__red {
      background-color: $red-900;
      color: $adm-white;

      // Hover
      &:hover {
        background: $red-800;
      }

      // Focus
      &:focus:not(:active), &.is-focused {
        background: $red-900;
        border: 2px solid $adm-white;
        box-shadow: 0 0 0 2px $red-900;
      }

      // Active
      &:active, &.is-active {
        background: $red-1000;
      }

      // Disabled & Loading
      &.is-disabled, &.is-loading {
        background: $red-600;
      }
    }

    // Green
    &__green {
      background-color: $green-900;
      color: $adm-white;

      // Hover
      &:hover {
        background: $green-800;
      }

      // Focus
      &:focus:not(:active), &.is-focused {
        background: $green-900;
        border: 2px solid $adm-white;
        box-shadow: 0 0 0 2px $green-900;
      }

      // Active
      &:active, &.is-active {
        background: $green-1000;
      }

      // Disabled & Loading
      &.is-disabled, &.is-loading {
        background: $green-600;
      }
    }
  }

  // Ghost
  &__ghost {

    // Blue
    &__blue {
      background-color: $adm-white;
      color: var(--primary-900);
      border: 1px solid var(--primary-900);

      // Hover
      &:hover {
        background-color: var(--primary-300);
      }

      // Focus
      &:focus:not(:active), &.is-focused {
        box-shadow: 0 0 0 2px var(--primary-900);
      }

      // Active
      &:active, &.is-active {
        background: var(--primary-400);
      }

      // Disabled & Loading
      &.is-disabled, &.is-loading {
        background: $adm-white;
        border: 1px solid var(--primary-800);
        --spinner-line-color: var(--primary-800);

        span, i {
          color: var(--primary-800);
        }
      }
    }

    // Dark & Red
    &__dark, &__red {
      background-color: $adm-white;
      color: $shade-900;
      border: 1px solid $shade-450;

      // Hover
      &:hover {
        background-color: $shade-150;
      }

      // Focus
      &:focus:not(:active), &.is-focused {
        border: 1px solid $shade-900;
        box-shadow: 0 0 0 2px $shade-900;
      }

      // Active
      &:active, &.is-active {
        background: $shade-250;
      }

      // Disabled & Loading
      &.is-disabled, &.is-loading {
        background: $adm-white;
        border: 1px solid $shade-450;
        --spinner-line-color: #808A9E;

        span, i {
          color: $shade-600;
        }
      }
    }

    // Red
    &__red {

      // Icon
      i {
        color: $red-900;
      }

      // Disabled & Loading
      &.is-disabled, &.is-loading {
        --spinner-line-color: #F487AE;

        i {
          color: $red-700;
        }
      }
    }
  }

  // Borderless
  &__borderless {
    border: 0;

    // Blue
    &__blue {
      background-color: transparent;
      color: var(--primary-900);
      border: 1px solid transparent;

      // Hover
      &:hover {
        background-color: var(--primary-300);
      }

      // Focus
      &:focus:not(:active), &.is-focused {
        box-shadow: 0 0 0 2px var(--primary-900);
        border: 1px solid var(--primary-900);
      }

      // Active
      &:active, &.is-active {
        background-color: var(--primary-400);
      }

      // Disabled & Loading
      &.is-disabled, &.is-loading {
        background-color: transparent;
        border: 1px solid transparent;
        --spinner-line-color: #669CF5;

        span, i {
          color: var(--primary-700);
        }
      }
    }

    // Red
    &__red {
      color: $shade-900;

      // Hover
      &:hover {
        background-color: var(--primary-300);
      }

      // Icon
      i {
        color: $red-900;
      }

      // Disabled & Loading
      &.is-disabled, &.is-loading {
        --spinner-line-color: #F487AE;

        i {
          color: $red-700;
        }
      }
    }

    // Dark
    &__dark {
      background-color: transparent;
      color: $shade-900;
      border: 1px solid transparent;

      // Hover
      &:hover {
        background-color: $shade-250;
      }

      // Focus
      &:focus:not(:active), &.is-focused {
        box-shadow: 0 0 0 2px $shade-900;
        border: 1px solid $shade-900;
      }

      // Active
      &:active, &.is-active {
        background-color: $shade-300;
      }

      // Disabled & Loading
      &.is-disabled, &.is-loading {
        background-color: transparent;
        border: 1px solid transparent;
        --spinner-line-color: #808A9E;

        span, i {
          color: $shade-600;
        }
      }
    }

    // Light
    &__light {
      background-color: transparent;
      color: $adm-white;
      border: 1px solid transparent;

      // Hover
      &:hover {
        background-color: rgba($adm-white, 0.15);
      }

      // Focus
      &:focus:not(:active), &.is-focused {
        box-shadow: 0 0 0 2px $adm-white;
        border: 1px solid $adm-white;
      }

      // Active
      &:active, &.is-active {
        background-color: rgba($adm-white, 0.25);
      }

      // Disabled & Loading
      &.is-disabled, &.is-loading {
        background-color: transparent;
        border: 1px solid transparent;

        span, i {
          color: rgba($adm-white, 0.5);
        }
      }
    }
  }

  // Disabled & Loading
  &.is-disabled, &.is-loading {
    cursor: not-allowed;
  }

  // Text
  span {
    display: inline-block;
  }

  // Icon
  i {
    display: inline-block;

    &:before {
      display: block;
      color: inherit;
    }
  }

  // One Button Next To Another
  + .adm-button-group {
    margin-left: 12px;
  }

  // Spinner Container
  .adm-button-spinner-container {
    width: var(--spinner-container-width);
    height: var(--spinner-container-height);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Spinner
  .adm-button-spinner {
    --spinner-size: 5;
    --spinner-line-alpha: 1;
    --spinner-ring-color: #FFFFFF;
    --spinner-ring-alpha: 0;
    --spinner-ring-size: 5;

    display: block;
    padding: 2px;
    font-size: calc(var(--spinner-size) * 1em);
    border-radius: 50%;

    // Spinner Line
    &__line {
      fill: none;
      stroke: var(--spinner-line-color);
      stroke-width: var(--spinner-ring-size);
      opacity: var(--spinner-line-alpha);
      stroke-linecap: round;
      transform-origin: 50% 50%;
      transform: rotate3d(0, 0, 1, 0deg);
      animation: 2156ms spinner-arc ease-in-out infinite,
      1829ms spinner-rotate linear infinite;
    }

    // Spinner Ring
    &__ring {
      fill: none;
      stroke: var(--spinner-ring-color);
      stroke-width: var(--spinner-ring-size);
      opacity: var(--spinner-ring-alpha);
    }
  }

  @keyframes spinner-rotate {
    to {
      transform: rotate3d(0, 0, 1, 360deg);
    }
  }

  @keyframes spinner-arc {
    from {
      stroke-dasharray: 0 150;
      stroke-dashoffset: 0;
    }
    to {
      stroke-dasharray: 100 150;
      stroke-dashoffset: -140;
    }
  }
}
</style>
